const contactEmail = [
  {
    country: 'ROU',
    postal: 'ro',
    email: 'servicii.online@syngenta.com',
    phone: '+40 21 528 12 00',
  },
  {
    country: 'POL',
    postal: 'pl',
    email: 'syngenta.polska@syngenta.com',
    phone: '+48 (22) 550 27 11',
  },
  {
    country: 'HUN',
    postal: 'hu',
    email: 'e.vetomag@syngenta.com',
    phone: '+36 70 123 45 67',
  },
  {
    country: 'BGR',
    postal: 'bg',
    email: 'bulgaria.office@syngenta.com',
    phone: '+359 882 735 800',
  },
  {
    country: 'DEU',
    postal: 'de',
    email: 'e.seeds@syngenta.com',
    phone: '+49 5222 5308012',
  },
  {
    country: 'UKR',
    postal: 'ua',
    langCode: 'uk',
    email: 'syngenta.ua@syngenta.com',
    phone: '+38 (044) 494 17 71',
  },
  {
    country: 'ZAF',
    postal: 'za',
    email: 'https://sensako.co.za/Sales',
    phone: '',
  }
];

export const getEmailId = (countryCode: string | null): string => {
  return contactEmail.find((data) => data.country === countryCode)?.email ?? 'contact@syngenta.com';
};

interface ContactDetails {
  email: string;
  phone: string;
}

const DUMMY_MAIL = 'contact@syngenta.com';
const DUMMY_PHONE_NUMBER = '01223 883400';
const langCode = localStorage.getItem('i18nextLng');

export const getContactDetails = (countryCode: string): ContactDetails => {
  const contactDetail: ContactDetails = {
    email: DUMMY_MAIL,
    phone: DUMMY_PHONE_NUMBER,
  };
  return contactEmail.find((data) => data.postal === countryCode.toLocaleLowerCase()) ??
  contactEmail.find(
    (data) =>
      data.postal.toLocaleLowerCase() === langCode ||
    data.langCode?.toLocaleLowerCase() === langCode
  ) ?? contactDetail;
};
