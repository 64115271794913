/* eslint-disable indent */
import axios, { CancelToken } from 'axios';
import { Attribute } from 'base/types/Attributes';
import {
  RecommendationInputBody,
  RecommendationInputResponse,
  TPinInputApiResponse,
} from 'base/types/RecommendationInput';
import { FieldSoilRequest } from './../types/SoilType';
import { SoilTypeResponse } from 'base/types/SoilType';
import API from './config';
import {
  RecCropwiseApiInputs,
  RecommendationCropwiseResponse,
} from './../types/RecommendationCropwise';
import { BreakoutsGlobalResponse } from 'base/types/BreakoutsGlobalResponse';
import { ProductSeed } from 'base/types/Products';
import { WeatherResponse } from 'base/types/Weather';

import { objectToQueryParams } from 'utils/urlSearchParams';
import { AttributesForFields, TPPForFields } from 'base/types/Fields';
import { decrypt } from '../../utils/crypto';
import { RecaptchaPayload, RecaptchaAPIResponse } from 'base/types/Recaptcha';
import { BreakoutsProductsSummaryResponse } from 'base/types/BreakoutsProductsSummaryResponse';
import { DetectFieldResponse, DetectFieldPayload } from 'base/types/detectField';
import { UserDetails } from 'base/types/UserDetails';

interface GetBreakoutsGlobaParams {
  productName: string;
  crop: string;
  country: string;
  yieldLevel?: string;
  texture?: string;
}

interface GetCountriesParams {
  params?: { filterBy?: string };
  cancelToken?: CancelToken;
}

interface GetAttributesParams {
  params: { crop?: string; countryCode?: string; cropId?: number | string; usageGroups?: string[] };
  cancelToken?: CancelToken;
}

interface GetFieldSoilsParams {
  params: { fields?: FieldSoilRequest[]; cropRegion?: string; brandName?: string };
  cancelToken?: CancelToken;
}

interface GetProductMaturityRangeFAOParams {
  params: { crop?: string; countryCode?: string };
  cancelToken?: CancelToken;
}

interface GetProductsParams {
  params: { crop?: string; countryCode?: string };
  cancelToken?: CancelToken;
}

interface GetWeatherParams {
  lat: number;
  lng: number;
  startDate: string;
  endDate: string;
  cancelToken?: CancelToken;
}

interface ISendMarketingCommercialEmail {
  recommendationSolutionId: string;
  utm_source?: string;
  utm_content?: string;
  utm_term?: string;
  utm_campaign?: string;
  utm_medium?: string;
}

interface GetTPPAttributes {
  params: { countryCode?: string; cropId?: number | string; centroid: number[] | [] };
  cancelToken?: CancelToken;
}

interface GetFieldsAttributes {
  params: { country?: string; cropId?: number | string; long: number; lat: number };
  cancelToken?: CancelToken;
}
interface GetDownyMildewAttributes {
  params: { tppRegion: string; cropRotation: string; tillageSystem: string; soilType: string };
  cancelToken?: CancelToken;
}

interface VerifyLandArableParams {
  params: {
    latitude: number;
    longitude: number;
  };
  cancelToken?: CancelToken;
}

const buildAttributesEndpoint = ({
  crop,
  countryCode,
  cropId,
  usageGroups,
}: {
  crop?: string;
  countryCode?: string;
  cropId?: number | string;
  usageGroups?: string[];
}) => {
  let endpoint = '/attributes';

  if (crop || countryCode || cropId || (usageGroups && usageGroups.length > 0)) {
    endpoint += '?';

    if (crop) {
      endpoint += `crop=${crop}`;
    }

    if (countryCode) {
      if (crop) {
        endpoint += '&';
      }
      endpoint += `countryCode=${countryCode}`;
    }

    if (cropId) {
      if (crop || countryCode) {
        endpoint += '&';
      }
      endpoint += `cropId=${cropId}`;
    }

    if (usageGroups && usageGroups.length > 0) {
      if (crop || countryCode || cropId) {
        endpoint += '&';
      }
      endpoint += `usageGroups=${usageGroups.join(',')}`;
    }
  }
  return endpoint;
};

class ProxyLayer {
  private _api;
  private _api_uat;

  constructor() {
    this._api = API(`${process.env.REACT_APP_PROXY_LAYER_URL}/proxy-layer/v1`);
    this._api_uat = API(`${process.env.REACT_APP_PROXY_LAYER_URL_UAT}/proxy-layer/v1`);
  }

  cancelToken() {
    return axios.CancelToken.source();
  }

  async getCountries(config?: GetCountriesParams) {
    const { params, cancelToken } = config ?? {};
    const res = await this._api.get('/countries', {
      cancelToken,
      params,
    });
    return res.data;
  }

  capitalizeWords(words: string) {
    return words.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  async getAttributes(config: GetAttributesParams) {
    const endpoint = buildAttributesEndpoint(config.params);
    const response = await this._api.get(endpoint);
    // sort attributes
    const sortedAttributes = response.data.sort(
      (attribute_a: Attribute, attribute_b: Attribute) => attribute_a.orderNumber - attribute_b.orderNumber
    ).map((attribute: Attribute) => {
      return {
        ...attribute,
        attributeOriginal: attribute.attributeName,
        attributeName: this.capitalizeWords(attribute.attributeName)
      };
    });
    return sortedAttributes as Attribute[];
  }

  async getFieldSoils(config: GetFieldSoilsParams) {
    const { fields, cropRegion, brandName } = config.params;
    const response = await this._api.post('/soils', {
      fields,
      cropRegion,
      brandName,
    });
    return response.data as SoilTypeResponse[];
  }

  async getProducts(config: GetProductsParams): Promise<ProductSeed[]> {
    const { crop, countryCode } = config.params;
    const response = await this._api.get('/products', {
      params: { country: String(countryCode).trim(), crop },
      cancelToken: config.cancelToken,
    });

    return response.data;
  }

  async getProductMaturityRangeFAO(config: GetProductMaturityRangeFAOParams) {
    const { crop, countryCode } = config.params;
    const response = await this._api.get(`/products/fao?crop=${crop}&country=${countryCode}`, {
      params: { country: String(countryCode).trim(), crop },
    });
    return response.data;
  }

  async postRecommendationInput(body: RecommendationInputBody) {
    const response = await this._api.post('/recommendation-input', body);
    return response.data;
  }

  async isSyngentaUser(email: string) {
    try {
      const result = await this._api_uat.get(`/is-syngenta-user?email=${email}`);
      return result.data;
    } catch (e) {
      return { status: false };
    }
  }

  formatIds(data: any) {
    try {
      data.recommendations.forEach((r: any) => {
        if (
          r.multi_field.assignments.length === 1 &&
          r.multi_field.assignments[0].field_id !==
          r.multi_field.geometry.properties[0].fields[0].id
        ) {
          r.multi_field.geometry.properties[0].fields[0].id = r.multi_field.assignments[0].field_id;
        }
      });
    } catch (e) {
      console.log(e);
    }
    return data;
  }

  async getRecommendationInput(recommendation_id: string, cancelToken?: CancelToken) {
    const response = await this._api.get(`/recommendation-input/${recommendation_id}`, {
      cancelToken,
    });
    return {
      ...response.data,
      productMaturityRange: JSON.parse(response.data.productMaturityRange),
      agronomicWeightings: JSON.parse(response.data.agronomicWeightings),
      boundaries: [].concat(response.data.boundaries).map((boundary: any) => ({
        ...boundary,
        selectedSoils: JSON.parse(boundary.selectedSoils || '[]'),
        soils: JSON.parse(boundary.soils || '[]'),
        fieldsResolution: boundary.fieldsResolution || 0,
      })),
      pins: [].concat(response.data.pins).map((pin: TPinInputApiResponse) => ({
        ...pin,
        selectedSoils: JSON.parse(pin.selectedSoils || '[]'),
        soils: JSON.parse(pin.soils || '[]'),
        fieldsResolution: pin.fieldsResolution || 0,
      })),
    } as RecommendationInputResponse;
  }

  async getRecommendationCropwise(input: RecCropwiseApiInputs, cancelToken?: CancelToken) {
    const recommendationPageURL =
      input?.source.toUpperCase() === 'DDG'
        ? `/recommendation-cropwise?solutionId=${input.solutionId}`
        : `/recommendation-cropwise?${input.decodedRecParamString}`;
    const response = await this._api.get(recommendationPageURL, {
      cancelToken,
    });
    return this.formatIds(response.data) as RecommendationCropwiseResponse;
  }

  async getRecommendationValidated(body: RecommendationInputBody) {
    const response = await this._api.post(`/validate-recommendation`, body);
    return response.data;
  }

  async getBreakoutsGlobal({
    productName = 'SY_Solandri',
    crop = 'corn',
    country = 'Ukraine',
    yieldLevel,
    texture,
  }: GetBreakoutsGlobaParams) {
    let body: any = {
      entry: {
        Entry: productName,
        Crop: crop,
        Country: country,
        Region: 'EAME',
        Type: 'HIGH_NAME',
        IsTraitedVersion: false,
      },
      exportImages: false,
    };
    if (texture && yieldLevel) {
      body = {
        ...body,
        Filters: [
          { Key: 'Yield Level', Values: yieldLevel },
          { Key: 'Texture', Values: texture },
        ],
      };
    }
    const response = await this._api.post('/breakouts-global', body);
    const encryptedResponse = await decrypt(response.data);
    return JSON.parse(encryptedResponse) as unknown as BreakoutsGlobalResponse;
  }

  async getBreakoutsGlobalRecommendationId({
    solutionId,
    withFilters = false,
    activeProduct = '',
    source = '',
    decodedRecParamString = '',
  }: {
    solutionId: string;
    withFilters: boolean;
    activeProduct: string;
    source: string;
    decodedRecParamString?: string;
  }) {
    const body: any = {
      solutionId,
      withFilters,
      activeProduct,
      source,
      ...(decodedRecParamString && { decodedRecParamString: decodedRecParamString }),
    };
    const config = {
      headers: { Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN_BREAKOUTS_GLOBAL}` },
    };
    const response = await this._api.post('/breakouts-global', body, config);
    const encryptedResponse = await decrypt(response.data);
    return JSON.parse(encryptedResponse) as unknown as BreakoutsGlobalResponse;
  }

  async getProductSummaryRecommendationId({
    solutionId,
    withFilters = false,
    activeProduct = '',
    source,
    decodedRecParamString,
  }: {
    solutionId: string;
    withFilters: boolean;
    activeProduct: string;
    source: string;
    decodedRecParamString?: string;
  }) {
    const body: any = {
      solutionId,
      withFilters,
      activeProduct,
      source,
      decodedRecParamString,
    };
    const config = {
      headers: { Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN_BREAKOUTS_GLOBAL}` },
    };
    const response = await this._api.post('/breakouts-products-summary', body, config);
    const encryptedResponse = await decrypt(response.data);
    return JSON.parse(encryptedResponse) as unknown as BreakoutsProductsSummaryResponse;
  }
  async renderPDF(body: { [key: string]: any }) {
    const response = await this._api.post('/render-pdf', body, {
      responseType: 'blob',
      headers: {
        Accept: 'application/pdf',
      },
    });
    return response.data;
  }

  async getWeather(config: GetWeatherParams): Promise<WeatherResponse[]> {
    const { lat, lng, startDate, endDate, cancelToken } = config;
    const response = await this._api.get(
      `/weather?plantingDate=${startDate}&harvestingDate=${endDate}&latitude=${lat}&longitude=${lng}&frequency=weekly`,
      {
        cancelToken,
      }
    );
    return response.data;
  }

  async sendMarketingCommercialEmail(params: ISendMarketingCommercialEmail) {
    const queryParams = objectToQueryParams(params);
    await this._api.post(`/marketing-email?${queryParams}`);
  }

  async resendMarketingCloudEmail(recommendationSolutionId: string) {
    await this._api.post('/marketing-cloud/resend-events', { recommendationSolutionId });
  }

  async resendMarketingCloudSms(
    recommendationSolutionId: string,
    userLanguage: string,
    phoneNumber?: string
  ) {
    await this._api.post('/marketing-cloud/send-sms', {
      recommendationSolutionId,
      userLanguage,
      phoneNumber,
    });
  }

  async updateContact(
    oldEmail: string,
    newEmail: string,
    newPhoneNumber: string,
    smsOptin: boolean,
    recommendationSolutionId: string
  ) {
    await this._api.post('/update-contact', {
      oldEmail,
      email: newEmail,
      newPhoneNumber,
      recommendationUUID: recommendationSolutionId,
      smsOptin,
    });
  }

  async getTPPForFields(config: GetTPPAttributes): Promise<TPPForFields> {
    const { cropId, countryCode, centroid } = config.params;
    const response = await this._api.post(
      `/tpp-zone?country=${countryCode}&id=${cropId}&type=attributes`,
      [centroid],
      {
        cancelToken: config.cancelToken,
      }
    );
    return response.data;
  }

  async getFieldsAttributes(config: GetFieldsAttributes): Promise<AttributesForFields> {
    const { country, cropId, long, lat } = config.params;
    const response = await this._api.get(
      `/check-attributes-for-fields?country=${country}&crop_id=${cropId}&longitude=${long}&latitude=${lat}`,
      {
        cancelToken: config.cancelToken,
      }
    );
    return response.data;
  }

  async getDownyMildew(config: GetDownyMildewAttributes): Promise<TPPForFields> {
    const { tppRegion, cropRotation, tillageSystem, soilType } = config.params;
    const response = await this._api.get(
      `/downy-mildew?tppRegion=${tppRegion}&cropRotation=${cropRotation}&tillageSystem=${tillageSystem}&soilType=${soilType}`,
      {
        cancelToken: config.cancelToken,
      }
    );
    return response.data;
  }

  async verifyRecaptcha(config: RecaptchaPayload): Promise<RecaptchaAPIResponse> {
    const { token } = config.params;
    const response = await this._api.post(
      `/recaptcha-verify`,
      {
        token,
      },
      {
        cancelToken: config.cancelToken,
      }
    );
    return response.data;
  }

  async verifyLandArable(
    lat: number,
    lng: number,
    cancelToken?: CancelToken
  ): Promise<{ arable_land: boolean }> {
    try {
      const response = await this._api.get('/arableLand', {
        params: { longitude: lng, latitude: lat },
        cancelToken,
      });
      return response.data;
    } catch (e) {
      console.log('Error!!', e);
    }
    return { arable_land: true };
  }
  async checkAvailableFields(
    detectFieldPayload: DetectFieldPayload,
    cancelToken?: CancelToken
  ): Promise<DetectFieldResponse> {
    const body: any = detectFieldPayload;
    const response = await this._api.post('/detectField', body, {
      cancelToken,
    });
    return response.data;
  }
  async getNonDDGUserDetails(email: string, cancelToken: CancelToken): Promise<UserDetails> {
    const response = await this._api.get('/user-details', {
      params: { login: email },
      cancelToken: cancelToken,
    });
    return response.data;
  }
}
export default new ProxyLayer();
