import { Boundary, RecommendationInput } from 'base/types/RecommendationInput';
import getBiggestSoilArea from './getBiggestSoilArea';
import { AgronomicInputs, AgronomicWeighting, Field, PinInformation } from 'context/store/recommendationFormReducer';
import { AGInput, getProductList } from './helpers/filterProducts';
import { TFunction } from 'i18next';
import moment from 'moment';
import { CropConstants } from './constants/Crop';
import { formatTraits } from './trait';
import { AttributesForFields } from 'base/types/Fields';
import { Country } from 'base/types/Countries';
import { Attribute } from 'base/types/Attributes';

export const retrieveRecommendationInputData = (
  agronomicInputs: AgronomicInputs, otherAgInput: AGInput,
  i18n: any, countryCode: string | undefined, countryRegion: string | undefined, agronomicWeightings: AgronomicWeighting[],
  rankingCrop: string | undefined, t: TFunction, countryList: Country[], attributes: Attribute[], fieldsAttributes?: AttributesForFields
) => {
  const {
    plantingDate,
    yieldRangeId,
    yieldRangeLabel,
    harvestDate,
    rotationIntensity,
    tillagePractice,
    selectedHerbicideTrait,
    selectedSegment,
    selectedBroomrape,
    productMadurityRange,
    productCountBasedOnUserSelectedFAO,
    areFieldsIrrigated,
    adjustedProductMaturityRange,
  } = agronomicInputs;

  const { selectedCrop, tppData } = otherAgInput;

  let productMaturityRange = '';
  if (productMadurityRange) {
    productMaturityRange = JSON.stringify({
      minFAO: productMadurityRange[0] || null,
      maxFAO: productMadurityRange[1] || null,
    });
  }
  let adjustedProductMaturityRangeValue = '';
  if (adjustedProductMaturityRange) {
    adjustedProductMaturityRangeValue = JSON.stringify({
      minFAO: adjustedProductMaturityRange[0] || null,
      maxFAO: adjustedProductMaturityRange[1] || null,
    });
  }
  const agProductNames = getProductList(otherAgInput, agronomicInputs, countryCode, fieldsAttributes);
  const plantingDateFormatted = plantingDate
    ? moment(plantingDate).format('YYYY-MM-DD')
    : null;
  const harvestDateFormatted = harvestDate
    ? moment(harvestDate).format('YYYY-MM-DD')
    : null;

  const getProductMaturityRange = () => {
    if (productCountBasedOnUserSelectedFAO === -1) {
      return productMaturityRange;
    }
    return adjustedProductMaturityRangeValue;
  };
  const countryName = countryList.find((item) => item.shortCode === countryCode)?.name ?? '';

  // yieldRanking from ag. weighting list as we want to promote the use of max yield as default
  const defaultAgWeighting = attributes.find((a: any) => a.attributeId === 'yieldRanking');
  const updatedAgWeightings = Object.assign({}, agronomicWeightings);
  if (defaultAgWeighting) {
    updatedAgWeightings[Object.keys(agronomicWeightings).length] = {
      id: defaultAgWeighting.id,
      attributeId: defaultAgWeighting.attributeId,
      attributeName: defaultAgWeighting.attributeName,
      value: defaultAgWeighting.attributeBestvalue ?? 10
    };
  }

  const recommendationInput: RecommendationInput = {
    cropId: selectedCrop?.id,
    cropName: selectedCrop?.name,
    countryCode: countryCode,
    countryRegion: countryRegion,
    // Summer corn is crop name and type is summer corn-winter so we need to send crop name for brazil
    cropType: countryCode === CropConstants.BRAZIL_CODE ? selectedCrop?.name || '' : selectedCrop?.type || selectedCrop?.name || '',
    productMaturityRange: getProductMaturityRange(),
    historicalYieldRangeId: yieldRangeId,
    historicalYieldRangeLabel: yieldRangeLabel,
    targetedPlantingDate: plantingDateFormatted,
    targetedHarvestDate: harvestDateFormatted,
    fieldsIrrigated: areFieldsIrrigated,
    agronomicWeightings: updatedAgWeightings,
    rankingCrop: rankingCrop ?? '',
    agInputProductNames: agProductNames,
    ...((selectedCrop?.id === CropConstants.CORN_CROP_ID ||
      selectedCrop?.id === CropConstants.SILAGE_CORN_CROP_ID ||
      selectedCrop?.id === CropConstants.SUMMER_CORN_CROP_ID) && {
      extraParameters: {
        productCountBasedOnUserSelectedFAO: productCountBasedOnUserSelectedFAO,
        productMaturityRange: productMaturityRange, // sending user selected fao range to display in AG Preference Section
        selectedLanguage: i18n.language,
        country: countryName
      },
    }),
    ...(selectedCrop?.id === CropConstants.SUNFLOWER_CROP_ID && {
      extraParameters: {
        rotation: rotationIntensity,
        tillage:
          tillagePractice === CropConstants.NO_TILLAGE
            ? CropConstants.MINIMAL_TILLAGE
            : tillagePractice,
        broomRape: selectedBroomrape[0] === 'F+' ? ['F', 'E'] : selectedBroomrape,
        broomRapeUserSelection: selectedBroomrape,
        cropSegments: selectedSegment,
        downyMildew: otherAgInput?.downyMildew?.value,
        traits: formatTraits(selectedHerbicideTrait),
        selectedLanguage: i18n.language,
        country: countryName
      },
    }),
    ...(selectedCrop?.name?.toLowerCase() === 'wheat' && {
      extraParameters: {
        spatialClass: tppData?.spatialClass?.length ? tppData?.spatialClass[0] : '',
        selectedLanguage: i18n.language,
        country: countryName
      },
    }),
  };
  return recommendationInput;
};

export const retrieveBoundariesData = (fields: Field[]) => {
  const boundaries: Boundary[] = fields.map((field: any) => {
    let [biggestSoilArea = ''] = field.selectedSoils ?? [];

    if (field.selectedSoils && field.selectedSoils?.length > 1) {
      const biggestArea = getBiggestSoilArea({
        soilGrid: field.soils || [],
        fieldBoundaries: field.boundary.geometry,
        fieldsResolution: field.fieldsResolution ?? 0,
      });
      biggestSoilArea = biggestArea.texture_raw;
    }

    return {
      estimatedSize: parseFloat(field.hectares as string),
      boundaryName: field.fieldName,
      coordinates: {
        type: 'Polygon',
        coordinates: field.boundary.geometry.coordinates[0],
      },
      soils: field.soils,
      selectedSoils: JSON.stringify(field.selectedSoils),
      biggestSoilArea: biggestSoilArea,
      fieldsResolution: field.fieldsResolution,
    };
  });
  return boundaries;
};

export const retrievePinsData = (fields: Field[], pin: PinInformation) => {
  const circleBoundary = fields[0];
  let [biggestSoilArea = ''] = circleBoundary.selectedSoils ?? [];

  if (circleBoundary.selectedSoils && circleBoundary.selectedSoils?.length > 1) {
    const biggestArea = getBiggestSoilArea({
      soilGrid: circleBoundary.soils ?? [],
      fieldBoundaries: circleBoundary.boundary.geometry,
      fieldsResolution: circleBoundary.fieldsResolution ?? 0,
    });
    biggestSoilArea = biggestArea.texture_raw;
  }

  const pins = {
    estimatedSize: pin.hectaresSize,
    coordinates: {
      type: 'Point',
      coordinates: [pin.position?.lng, pin.position?.lat],
    },
    circleBoundary: {
      type: 'Polygon',
      coordinates: circleBoundary.boundary.geometry.coordinates[0],
    },
    soils: circleBoundary.soils,
    selectedSoils: JSON.stringify(circleBoundary.selectedSoils),
    biggestSoilArea: biggestSoilArea,
    fieldsResolution: circleBoundary.fieldsResolution,
  };
  return pins;
};
